<template>
    <div class="home">		
        <HomeComponent/>
    </div>
</template>

<script>
// @ is an alias to /src
import HomeComponent from '@/components/HomeComponent.vue'

export default {
	name: 'Home',
	title: 'Inicio',
    components: {
        HomeComponent,
	},
}
</script>

<style lang="scss" scoped>
.home {
	@include flex-container;
	position: absolute;
	top: 0;
	width: 100%;
}

img {
    position: absolute;
    z-index: 4;
    width: 516px;
    height: auto;
}

@include tabletLandscape {
	
	.home {
		position: relative;
		height: 85vh;
	}
}

@include justTabletPortrait {
	.home {
		height: 100%;
	}
}

@include mobile {

	.home {
		height: 127.8vh;
	}
}

@include mobileTiny {
	
	.home {
		margin-bottom: 20px;
		height: 133vh;
	}
}

</style>
