<template>
	<div class="home-content">
		<Promo             
			msg="REGISTRATE<br><span>Y RECIBE EL</span><br><b>30%</b><br><span>DE TU PRIMER <br> INGRESO</span>"
            btn="Regístrate"
			@promoClicked="show = true"
		/>
	
		<Social/>
		<transition appear name="bounce" enter-active-class="bounceInRight">
			<img id="rigth-bars" class="bars hide-mobile" src="../assets/img/rigth-bars.png" alt="">
		</transition>
		<transition appear name="bounce" enter-active-class="bounceInUp">
			<img id="left-bar" class="bars hide-mobile" style="animation-duration: 1.5s" src="../assets/img/left-bar.png" alt="">
		</transition>
		<transition appear name="bounce" enter-active-class="bounceInRight">
			<img id="orange-bars" class="bars hide-mobile" src="../assets/img/orange-bars.png" alt="">
		</transition>

		<Modal :show="show" @close="toggleModal" class="register">
			<template v-slot:title>
				<h3>Registro</h3>			
			</template>
			<template v-slot:form>
				<Register @close="toggleModal"/>				
			</template>
		</Modal>

	</div>
</template>

<script>
//const axios = require('axios')
import Promo from './Promo.vue'
import Social from './Social.vue'
import Register from './Register.vue'
import Modal from './Modal.vue'
export default {
    name: 'HomeComponent',
    components: { 
		Promo,
		Social,
		Register,
		Modal,
	},
    data() {
        return {
            categories: [],
			loading: false,
			show: false,
        }
    },
    created() {
		this.loading = true
    },
	methods: {
		register() {
			this.show = true
			this.$forceUpdate()
		},
		toggleModal() {
			this.show = !this.show
			this.$forceUpdate()
		}		
	},
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
.home-content {
	width: 100%;
	height: 1173px;
}
.bars {
	position: absolute;
	z-index: 1;
}

#rigth-bars {
	right: -.8%;
    bottom: -8.2%;
}

#left-bar {
	left: 33.5%;
    bottom: -14.6%;
}

#orange-bars {
	right: 3.75%;
	bottom: 6%;
	animation-duration: 2s;
}

@include desktopMax {

	#left-bar {
		left: 38vw;
	}
}

@include tabletLandscape {

	.home-content {		
		width: 100%;
	}

	.bars {
		display: none;
	}
}

@include mobile {

	.home-content {		
		width: 100%;
	}
}
</style>
